<template>
  <div class="iq-sidebar" @mouseleave="onMouseLeave">
    <!-- <div class="iq-sidebar" @mouseenter="onMouseEnter"  @mouseleave="onMouseLeave"> -->
    <!-- <div > -->
    <div class="iq-sidebar-logo d-flex justify-content-between">
      <router-link :to="{ name: 'leadList' }" style="text-decoration: none;">
        <div class="iq-light-logo">
          <img :src="imageBaseUrl + 'comapny-images/logo-icon.svg'" class="img-fluid" alt="logo">
          <div v-if="showNotificationDot" class="notification-dot"></div>
        </div>

        <span class="cms">{{ appName }} </span>

      </router-link>
      <div class="iq-menu-bt-sidebar">
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu">
            <NotificationBell @new-notification="handleNewNotification" @clear-all-notifications="clearNotificationDot"
              class="custom-notification-bell" />
            <!-- <div class="main-circle"><i class="ri-arrow-left-s-line"></i></div>
                    <div class="hover-circle"><i class="ri-arrow-right-s-line"></i></div> -->
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu mt-2 mb-3">
        <CollapseMenu v-if="SideBarItems && SideBarItems.length > 0" :items="SideBarItems" :open="true"
          :sidebarGroupTitle="true" />
      </nav>
    </div>
    <div class="iq-sidebar-logo d-flex justify-content-between p-0" style="position: absolute; bottom: 0%;">
      <template v-if="user">
        <div>
          <button v-b-popover.hover.top class="border-0 " id="popover-btn"
            style="background-color: white;max-width: 260px;min-width: auto; height: 68px;">
            <div class="search-toggle iq-waves-effect d-flex align-items-center"
              style="border-radius: 0 8px 8px 0 !important; min-width: auto; max-width: 248px;">

              <img :src="user.full_image_url" v-if="user.full_image_url" class="img-fluid rounded" height="100px"
                width="50px" />
              <img src="../../../../assets/images/user/pngwing.com.png" v-else class="img-fluid rounded" height="50px"
                width="50px" />

              <!-- v-if="sidebarMini != false" -->
              <div class="ml-2 text-left d-none" id="user-info" style="min-width: 194px;max-width: 194px;">
                <h6 class="line-height name">
                  {{ user.name }}
                  <b-form-checkbox type="checkbox" size="lg" :disabled="rc_widget"
                    class="custom-control custom-switch custom-switch-icon custom-control-inline float-right mr-1"
                    v-model="rc_widget" id="rc_widget">
                  </b-form-checkbox>
                  <!-- <div class="float-right mr-2" style="-webkit-transition: all 0.3s ease-out 0s;">
                    <b-form-checkbox type="checkbox" size="xl"
                      class="custom-control custom-switch custom-switch-icon custom-control-inline"
                      :checked="user.call_queue_presence == '1'" @change="updateQueuePresence" id="callqueue">
                      <span class="loader" v-if="loading"></span>
                    </b-form-checkbox>
                  </div> -->
                </h6>
                <div class="float-left d-inline">
                  <span class="m-0" style="font-size: small;line-height: 20px;">{{ user.direct_line + " " +
                    " Ext. " + user.extension }}</span>
                </div>
              </div>
            </div>
          </button>

          <b-popover v-if="user && user.id" target="popover-btn" triggers="hover" placement="top">

            <template #default>
              <div class="iq-card-body p-0">
                <router-link v-if="user.system == 1" to="/systems" class="iq-sub-card iq-bg-primary-hover"
                  style="text-decoration: none;">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary bg-back-white">
                      <i class="fa-solid fa-user-gear"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0">System</h6>
                    </div>
                  </div>
                </router-link>
                <router-link to="/user-logs" class="iq-sub-card iq-bg-primary-hover" style="text-decoration: none;">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary bg-back-white">
                      <i class="fa-solid fa-person-circle-question"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0">User Logs</h6>
                    </div>
                  </div>
                </router-link>
                <router-link to="/agent-list" class="iq-sub-card iq-bg-primary-hover" style="text-decoration: none;">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary bg-back-white">
                      <i class="fa-solid fa-address-book"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0">Agent List</h6>
                    </div>
                  </div>
                </router-link>
                <router-link to="" class="iq-sub-card iq-bg-primary-hover" style="text-decoration: none;">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary bg-back-white">
                      <i class="fa-brands fa-wikipedia-w"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0">Wiki</h6>
                    </div>
                  </div>
                </router-link>
                <router-link to="/statistics" class="iq-sub-card iq-bg-primary-hover" style="text-decoration: none;">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary bg-back-white">
                      <i class="fa-solid fa-chart-line"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0">Statistics</h6>
                    </div>
                  </div>
                </router-link>
                <router-link to="" class="iq-sub-card iq-bg-primary-hover" style="text-decoration: none;">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary bg-back-white">
                      <i class="fa-solid fa-calendar-plus"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0">Schedule</h6>
                    </div>
                  </div>
                </router-link>
                <router-link to="/change-password" class="iq-sub-card iq-bg-primary-hover"
                  style="text-decoration: none;">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary bg-back-white">
                      <i class="fa-solid fa-lock"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0">Change Password</h6>
                    </div>
                  </div>
                </router-link>
                <div class="d-flex" style="justify-content: space-around;">
                  <div class="float-left mt-2">
                    <b-button class="btn btn-primary" @click="logout">signout
                      <i class="ri-login-box-line ml-2"></i></b-button>
                  </div>

                </div>
              </div>
            </template>
          </b-popover>
        </div>
      </template>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { core, APPNAME } from '../../../../config/pluginInit'
import Vue from 'vue';
import CollapseMenu from '../../menus/CollapseMenu'
import { mapActions, mapGetters } from "vuex";
import Lottie from "../../../../components/core/lottie/Lottie";
import NotificationBell from "../../../Global-Component/notificationbell/NotificationBell.vue";
import { _ } from "vue-underscore";
import { BButton, BPopover } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import SideBarItems from '../../../../FackApi/json/SideBar'

export default {
  name: 'SidebarStyle',
  props: {

    SideBarItems: {
      type: Array,
      default: [{
        menu_link: ""
      }]
    }
  },
  components: {
    Lottie,
    CollapseMenu,
    BButton,
    BPopover,
    NotificationBell
  },
  watch: {
    rc_widget(newValue, oldVal) {
      localStorage.setItem("rc_widget", newValue.toString());
    },
  },

  computed: {
    user() {
      return this.$store.state.userDetail;
    },
    ...mapGetters({
      bookmark: "Setting/bookmarkState",
      langsOptions: "Setting/langOptionState",
      selectedLang: "Setting/langState",
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.sidebarMini();
    });


    core.SmoothScrollbar();
    this.handleNewNotification();
  },
  methods: {
    clearNotificationDot() {
      this.showNotificationDot = false;
    },
    handleNewNotification(notificationCount) {
      this.showNotificationDot = notificationCount > 0;
    },
    sidebarMini() {
      core.triggerSet();
      if (!this.horizontal) {
        document.addEventListener("click", this.closeSearch, true);
      }
    },
    onMouseEnter() {
      core.triggerSet();
      this.sidebarMini();
    },
    onMouseLeave() {
      this.onMouseEnter = null;
    },
    logout() {
      const _vm = this;
      this.axios
        .get("/logout")
        .then(function () {
          localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
          localStorage.removeItem('all_leads');
           localStorage.removeItem('targetUpdated');
          localStorage.removeItem('all_sales');
          localStorage.removeItem('rc_widget');
          localStorage.removeItem('call_queue_presence');
          localStorage.removeItem('user');
          localStorage.removeItem('menu');
          localStorage.removeItem('slugs');
          window.location.href = "/auth/login";
        })
        .catch(function (error) {
        });
    },
    ...mapActions({
      rtlAdd: "Setting/setRtlAction",
      rtlRemove: "Setting/removeRtlAction",
    }),
    updateQueuePresence() {
      const checkbox = document.getElementById('callqueue');
      this.loading = true;
      this.axios.get('/update-queue-presence')
        .then(response => {
          Vue.$toast.success(response.data.msg);
          this.loading = false;
        })
        .catch(error => {
          Vue.$toast.error(error.data.msg);
        });
    },

  },
  data() {
    return {
      appName: APPNAME,
      imageBaseUrl: process.env.VUE_APP_IMAGEBASEURL_URL,
      loading: false,
      apiUrl: process.env.VUE_APP_API_URL.replace("/api", ""),
      showNotificationDot: false,
      rc_widget: localStorage.getItem("rc_widget") === "true" || false,
      // SideBarItems
    }
  }
}
</script>

<style scoped>
input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 75px;
  height: 25px;
  background: grey;
  /* display: block; */
  border-radius: 100px;
  position: relative;
  margin-top: 1em;
}

label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked+label.active {
  background-color: #bada55;
}

input:checked+label.inactive {
  background-color: #ccc;
}

input:checked+label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.iq-card.shadow-none.m-0::-webkit-scrollbar {
  display: none;
}

.loader {
  display: none;
  /* initially hide the loader */
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.bg-back-white {
  background-color: #fff;
}

.notification-dot {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
  transform: translate(50%, -50%);
}

@keyframes pulse {
  0% {
    transform: scale(1) translate(50%, -50%);
  }

  50% {
    transform: scale(1.5) translate(50%, -50%);
  }

  100% {
    transform: scale(1) translate(50%, -50%);
  }
}



@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
